<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row class="top_l">
					<template v-for="(item, index) in this.$TOOL.data.get('USER_INFO').sysResourcesEntities">
						<el-button
							v-if="(item.parentId == twoId && item.permission.includes('add')) || (item.parentId == twoId && item.permission.includes('batch'))"
							:key="index"
							type="primary"
							@click="update(item.permission.slice(9))"
						>
							{{ item.name }}
						</el-button>
					</template>
				</el-row>
				<el-table
					:data="tableData"
					border
					stripe
					v-loading="serloading"
					:header-cell-style="{ background: '#eef1f6' }"
					@selection-change="handleSelectionChange"
				>
					<el-table-column type="selection" width="50" align="center"></el-table-column>
					<el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
					<el-table-column prop="name" label="菜单名称"></el-table-column>
					<el-table-column prop="type" label="菜单类型"></el-table-column>
					<el-table-column prop="url" label="菜单地址"></el-table-column>
					<el-table-column prop="permission" label="权限"></el-table-column>
					<el-table-column prop="sort" label="排序"></el-table-column>
					<el-table-column prop="createTime" label="创建时间"></el-table-column>
					<el-table-column prop="updateTime" label="更新时间"></el-table-column>
					<el-table-column label="操作" width="300" align="center">
						<template v-slot="scope">
							<el-button size="small" type="primary" plain @click="handleSee(scope.$index, scope.row)">查看</el-button>
							<template v-for="(item, index) in this.$TOOL.data.get('USER_INFO').sysResourcesEntities">
								<el-button
									v-if="item.parentId == twoId && !item.permission.includes('batch') && !item.permission.includes('add')"
									:key="index"
									size="small"
									type="primary"
									plain
									@click="update(item.permission.slice(9), scope.row)"
								>
									{{ item.name }}
								</el-button>
							</template>
						</template>
					</el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination
						:small="true"
						v-model:currentPage="currentPage"
						v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]"
						background
						layout="total, sizes, prev, pager, next, jumper"
						:total="+totals"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
					/>
				</div>
				<!-- 添加权限 -->
				<el-dialog v-model="dialogAdd" title="添加权限" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="110px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="菜单名称" prop="name"><el-input placeholder="请输入菜单名称" v-model="ruleForm.name"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="菜单类型" prop="type">
									<el-select v-model="ruleForm.type" placeholder="请选择菜单类型">
										<el-option label="button" value="button"></el-option>
										<el-option label="menu" value="menu"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="父ID" prop="parentId">
									<el-select v-model="ruleForm.parentId" placeholder="请选择父ID类型">
										<el-option :key="0" label="默认" :value="0"></el-option>
										<template v-for="item in jurData" :key="item.id">
											<el-option v-if="item.type == 'menu'" :label="item.name" :value="item.id"></el-option>
										</template>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="权限"><el-input placeholder="请输入权限" v-model="ruleForm.permission"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="排序" prop="sort"><el-input placeholder="请输入排序" v-model="ruleForm.sort"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="URL地址"><el-input placeholder="请输入URL地址" v-model="ruleForm.url"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="菜单图标"><el-input placeholder="请输入图标" v-model="ruleForm.icon"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="是否外部链接" prop="external">
									<el-radio v-model="ruleForm.external" :label="true">是</el-radio>
									<el-radio v-model="ruleForm.external" :label="false">否</el-radio>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
							<el-button @click="resetClick('addruleForm')">重置</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 查看详情 -->
				<el-dialog title="资源详情" v-model="dialogDetails" :fullscreen="fullscreenshow" width="50%">
					<el-descriptions border :column="2">
						<el-descriptions-item label="ID">{{ details.id }}</el-descriptions-item>
						<el-descriptions-item label="菜单名称">{{ details.name }}</el-descriptions-item>
						<el-descriptions-item label="菜单类型">{{ details.type }}</el-descriptions-item>
						<el-descriptions-item label="父ID">{{ details.parentId }}</el-descriptions-item>
						<el-descriptions-item label="权限">{{ details.permission }}</el-descriptions-item>
						<el-descriptions-item label="排序">{{ details.sort }}</el-descriptions-item>
						<el-descriptions-item label="是否外部链接">{{ details.external }}</el-descriptions-item>
						<el-descriptions-item label="菜单图标">{{ details.icon }}</el-descriptions-item>
						<el-descriptions-item label="创建时间">{{ details.createTime }}</el-descriptions-item>
						<el-descriptions-item label="更新时间">{{ details.updateTime }}</el-descriptions-item>
					</el-descriptions>
					<template #footer>
						<span class="dialog-footer"><el-button @click="dialogDetails = false">取 消</el-button></span>
					</template>
				</el-dialog>
				<!-- 修改权限 -->
				<el-dialog title="修改权限" v-model="dialogEdit" :fullscreen="fullscreenshow" width="50%">
					<el-form :model="EditruleForm" :rules="Editrules" ref="EditruleFormRrf" label-width="110px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="菜单名称" prop="name"><el-input v-model="EditruleForm.name"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="菜单类型" prop="type">
									<el-select v-model="EditruleForm.type" placeholder="请选择菜单类型">
										<el-option label="button" value="button"></el-option>
										<el-option label="menu" value="menu"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="权限"><el-input v-model="EditruleForm.permission"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="排序" prop="sort"><el-input v-model="EditruleForm.sort"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="URL地址"><el-input v-model="EditruleForm.url"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="菜单图标"><el-input v-model="EditruleForm.icon"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="是否外部链接" prop="external">
									<el-radio v-model="EditruleForm.external" :label="true">是</el-radio>
									<el-radio v-model="EditruleForm.external" :label="false">否</el-radio>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="EditsubmitForm('EditruleFormRrf')">提交</el-button>
							<el-button @click="dialogEdit = false">取消</el-button>
						</span>
					</template>
				</el-dialog>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'resources',
	data() {
		return {
			// 查看详情
			dialogDetails: false,
			details: [],
			multipleSelection: [],
			fullscreenshow: false,
			serloading: false,
			totals: null,
			tableData: [],
			emptyText: '暂无数据',
			currentPage: 1,
			pageSize: 10,
			twoId: null,
			// 添加权限
			jurData: [],
			dialogAdd: false,
			ruleForm: {
				name: '',
				type: '',
				parentId: '',
				permission: '',
				sort: '',
				external: true,
				url: '',
				icon: ''
			},
			rules: {
				name: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }],
				type: [{ required: true, message: '请输入菜单类型', trigger: 'change' }],
				parentId: [{ required: true, message: '请输入父ID', trigger: 'change' }],
				sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
				external: [{ required: true, message: '请输入外部链接', trigger: 'blur' }]
			},
			// 修改权限
			dialogEdit: false,
			EditruleForm: {
				name: '',
				type: '',
				permission: '',
				sort: '',
				external: '',
				id: '',
				url: '',
				icon: ''
			},
			Editrules: {
				name: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }],
				type: [{ required: true, message: '请输入菜单类型', trigger: 'change' }],
				sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
				external: [{ required: true, message: '请输入外部链接', trigger: 'blur' }]
			}
		};
	},
	created() {
		// 二级目录ID
		this.twoId = this.$route.query.Id;
		// 获取用户列表
		this.jurisdiction();
		if (document.body.clientWidth < 768) {
			this.fullscreenshow = true;
		} else {
			this.fullscreenshow = false;
		}
	},
	methods: {
		// 获取权限列表
		jurisdiction() {
			this.serloading = true;
			this.$HTTP.post(
				'resources/list',
				{
					pageNumber: this.currentPage,
					pageSize: this.pageSize
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 重置
		resetClick(name) {
			this.$refs[name].resetFields();
		},
		// 权限button按钮方法
		update(methodsNums, row) {
			//触发这个参数的方法
			this[methodsNums](row);
		},
		// 新增权限
		add() {
			this.dialogAdd = true;

			this.$HTTP.post(
				'resources/list',
				{
					pageNumber: 1,
					pageSize: 999
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						this.jurData = response.data.data.records;
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 添加权限
		submitClick(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'resources/add',
						{
							sort: this.ruleForm.sort,
							permission: this.ruleForm.permission,
							external: this.ruleForm.external,
							type: this.ruleForm.type,
							parentId: this.ruleForm.parentId,
							name: this.ruleForm.name,
							url: this.ruleForm.url,
							icon: this.ruleForm.icon
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogAdd = false;
									this.$message.success(response.data.msg);
									this.resetClick(formName);
									this.ruleForm.permission = ''
									this.ruleForm.url = ''
									this.ruleForm.icon = ''
									// 获取权限列表
									this.jurisdiction();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 多选
		handleSelectionChange(val) {
			this.multipleSelection = [];
			for (var i = 0; i < val.length; i++) {
				this.multipleSelection.push(val[i].id);
			}
		},
		// 批量删除
		batchDelete() {
			if (this.multipleSelection == '') {
				return this.$message.error('请选择删除内容');
			}
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$HTTP.post(
						'resources/remove',
						{
							ids: this.multipleSelection
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.$message.success(response.data.msg);
									// 获取权限列表
									this.jurisdiction();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
		},
		// 查看
		handleSee(index, row) {
			this.dialogDetails = true;
			this.$HTTP.post('resources/get/' + row.id + '', {}, response => {
				if (response.status >= 200 && response.status < 300) {
					this.details = response.data.data;
				} else {
					console.log(response.message);
				}
			});
		},
		// 修改
		edit(row) {
			this.EditruleForm.name = row.name;
			this.EditruleForm.type = row.type;
			this.EditruleForm.permission = row.permission;
			this.EditruleForm.sort = row.sort;
			this.EditruleForm.external = row.external;
			this.EditruleForm.id = row.id;
			this.EditruleForm.url = row.url;
			this.EditruleForm.icon = row.icon;
			this.dialogEdit = true;
		},
		// 修改权限
		EditsubmitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'resources/edit',
						{
							sort: this.EditruleForm.sort,
							permission: this.EditruleForm.permission,
							external: this.EditruleForm.external,
							type: this.EditruleForm.type,
							name: this.EditruleForm.name,
							id: this.EditruleForm.id,
							url: this.EditruleForm.url,
							icon: this.EditruleForm.icon
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogEdit = false;
									this.$message.success(response.data.msg);
									// 获取权限列表
									this.jurisdiction();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 删除
		delete(row) {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					var id = [];
					id.push(row.id);
					this.$HTTP.post(
						'resources/remove',
						{
							ids: id
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.$message.success(response.data.msg);
									// 获取权限列表
									this.jurisdiction();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
		},
		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			this.jurisdiction();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 获取用户列表
			this.jurisdiction();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
